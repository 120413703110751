import { currentYear } from "./utilities";

export const COPYRIGHT_TEXT = `© ${currentYear()} Piper Sandler Companies. Since 1895. All rights reserved.`;
export const EQUITY_RESEARCH = "Equity Research";
export const TABS_LABEL = {
  SEARCH: "HOME",
  ADVANCED_SEARCH: "ADVANCED SEARCH",
  SAVED_SEARCHES: "SAVED SEARCHES",
};
export const ONE_CLICK_SEARCHES_LABEL = {
  MOST_RECENT_RESEARCH: "Most Recent Research",
  AM_SUMMARIES: "AM Summaries",
  EARNINGS_CHANGES: "Earnings Changes",
  PRICE_TARGET_CHANGES: "Price Target Changes",
  RATINGS_CHANGES: "Ratings Changes",
};

export const SEARCH_BUTTON_LABEL = "SEARCH";
export const ONE_CLICK_SEARCHES_TEXT = "ONE CLICK SEARCHES";
export const DATEPICKER = {
  TO: "To",
  FROM: "From",
};

export const YOUR_SEARCH_CRITERIA_LABELS = {
  YOUR_SEARCH_CRITERIA_TEXT: "CURRENT SEARCH CRITERIA",
};

export const TYPEOFRESEARCH = {
  All_TYPES: "All Types",
  AMSUMMARY: "AM Summaries",
  // ASSET: "Asset Allocation Quarterly",
  COMPANY: "Company Reports/Notes",
  // PORTFOLIO: "Guided Portfolios",
  // HIGHYIELD: "High Yield Research",
  INDUSTRY: "Industry Reports/Notes",
  // INVESTOR: "Informed Investor",
  // TECHNOLOGY: "Technical Analysis",
  // TECHINVESTOR: "Technical Informed Investor",
  // WARPLAN: "Technical War Plan",
};
export const ANALYST_RATINGS_TEXT = "Piper Sandler Research Rating : ";
export const ANALYST_RATINGS_HEADER_TEXT = "Analyst Ratings for";
export const dateOptions = [
  { label: "Anytime", value: "Anytime" },
  { label: "Custom", value: "Custom" },
  { label: "In the last hour", value: "In the last hour" },
  { label: "In the last 2 hours", value: "In the last 2 hours" },
  { label: "In the last 6 hours", value: "In the last 6 hours" },
  { label: "In the last 12 hours", value: "In the last 12 hours" },
  { label: "In the last 18 hours", value: "In the last 18 hours" },
  { label: "In the last day", value: "In the last day" },
  { label: "In the last week", value: "In the last week" },
  { label: "In the last 2 weeks", value: "In the last 2 weeks" },
  { label: "In the last month", value: "In the last month" },
  { label: "In the last 3 months", value: "In the last 3 months" },
  { label: "In the last 6 months", value: "In the last 6 months" },
  { label: "In the last year", value: "In the last year" },
];

export const ONE_CLICK_SEARCH = {
  MOST_RECENT_RESEARCH: "IRPID::EQU::506&&DocumentDate::GEQ::{currentDate}",
  AM_SUMMARIES: "IRPID::EQU::506&&TYPEOFRESEARCH::EQU::AMSUMMARY",
  EARNINGS_CHANGES: "IRPID::EQU::506&&SUBJECTCODE::EQU::EARN",
  PRICE_TARGET_CHANGES: "SUBJECTCODE::EQU::TARG&&IRPID::EQU::506",
  RATINGS_CHANGES: "SUBJECTCODE::EQU::RECC&&IRPID::EQU::506",
};

export const GRAPH_SHOW_HIDE = {
  SHOW: "SHOW",
  HIDE: "HIDE",
};

export const TABLE_MESSAGES = {
  DEFAULT: "No Results. Please enter a search criteria to get started",
  NO_RESULT: "No Results. Please try with some other criteria",
  API_ERROR: "Something went wrong",
  START: "Please click on SEARCH to get the reports",
};
