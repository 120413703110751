import { React, useState } from "react";
import Box from '@mui/material/Box';
import Pagination from "@mui/material/Pagination";
import { ReactComponent as ArrowRight } from "icons/arrow-right-pagination.svg";
import { ReactComponent as ArrowLeft } from "icons/arrow-left-pagination.svg";
import PaginationItem from "@mui/material/PaginationItem";
import { Dropdown } from "components/Common/Dropdown";
import "./Pagination.scss";
import colors from "styles/colors.scss";
import { useEffect } from "react";

const TablePagination = ({ dataCount, rowsPerPage, onChange, currentPage }) => {
  const [page, setpage] = useState(1);
  let noOfPages = Math.ceil(dataCount / rowsPerPage);
  const handleChange = (event, page) => {
    onChange && onChange(page);
  };

  const totalPages = [...Array(noOfPages).keys()].map(key => (
    {
      label: key + 1,
      value: key + 1
    }
  ))

  useEffect(() => {
    setpage(currentPage);
  }, [currentPage])

  return (
    <div className="pagination__container">
      <Pagination
        count={noOfPages}
        siblingCount={0}
        page={page}
        size="large"
        onChange={handleChange}
        renderItem={(item) => (
          <PaginationItem
            disableRipple={true}
            sx={{
              "&.Mui-selected": {
                bgcolor: colors.whiteColor,
                borderBottom: 2,
                borderBottomColor: colors.blueDark,
                fontWeight: 400,
              },
              "&.Mui-selected:hover": {
                backgroundColor: "transparent",
              },
            }}
            className="pagination__paginationItem"
            components={{
              previous: ArrowLeft,
              next: ArrowRight,
            }}
            {...item}
          />
        )}
      />
      <Box className="pagination__goto">
        <span className="pagination__goto__txt">GO TO</span>
        <Dropdown
          containerClassName="pagination__goto__select"
          placeholder={"GOTO"}
          options={totalPages}
          onSelect={(value) => {
            onChange && onChange(value);
          }}
          selectedValues={[page]}
          showValueOnSelect
        />
      </Box>
      
    </div>
  );
};

export default TablePagination;
