import Button from "@mui/material/Button";
import "./Button.scss";

const CustomButton = (props) => {
  const { label, className, ...rest } = props;
  return (
    <Button {...rest} className={`button-custom ${className}`}>
      {label}
    </Button>
  )
}

export default CustomButton;