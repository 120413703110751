import { createTheme } from "@mui/material";
import variables from 'styles/variables.scss';
import colors from 'styles/colors.scss';

 const theme = createTheme({
  palette : {
  },
  typography: {
    fontFamily: variables.fontFamily,
  },
  components: {
    MuiTableHead : {
      styleOverrides : {
        root : {
          backgroundColor: colors.tableHeaderColor
        }
      }
    },
    MuiLink: {
      styleOverrides : {
        root: {
          color: colors.textColor,
          textDecorationColor: "currentcolor",
          cursor: "pointer",
          ':hover': {
            textDecoration: "none",
            color: colors.anchorHoverColor
          }
        }
      }
    }
  }
})

theme.typography.h4 = {
  fontSize: "23px",
  color: colors.textColor,
  lineHeight: "43px",
  fontWeight: "bold",
  [theme.breakpoints.down('md')]: {
    fontSize: "16px",
  },
}

export default theme;
