import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';

import { ReactComponent as DownArrow } from 'icons/down-arrow.svg';
import { ReactComponent as UpArrow } from 'icons/up-arrow.svg';
import Box from '@mui/material/Box';

const CustomTableHead = (props) => {
  const { order, orderBy, onRequestSort, columns, actions } = props;
  const createSortHandler = (property, isSorting) => (event) => {
    if(isSorting) {
      onRequestSort(event, property);
    }
  };

  return (
    <TableHead className="table__head">
      <TableRow>
        {
          columns.map((headCell) => (
            <TableCell key={headCell.field} align={headCell.numeric ? 'right' : 'left'} sx={{ width: `${headCell.width}%` }}>
              <span className={`table__head__cell ${headCell.field === orderBy ? "table__head__cell--active" : ""} ${headCell.sorting ? "table__head__cell--pointer" : ""}`} onClick={createSortHandler(headCell.field, headCell.sorting)}>
                {
                  headCell.title
                }
                {
                  headCell.sorting && (
                    <span className={`sorting-icon`}>
                      {
                        (headCell.field !== orderBy || (headCell.field === orderBy && order === 'desc')) && (<DownArrow className="sorting-icon__down-arrow" />)
                      }
                      {
                        (headCell.field !== orderBy || (headCell.field === orderBy && order === 'asc')) && (<UpArrow className="sorting-icon__up-arrow" />)
                      }
                      {
                        (headCell.field === orderBy) && (
                          <span className="sorting-icon__menu">
                            <i className="menu"></i>
                          </span>
                        )
                      }
                    </span>
                  )
                }

              </span>
            </TableCell>
          ))
        }
        {
          actions && actions.map((action, actionIndex) => (
            <TableCell key={`column_${actionIndex}`} sx={{ width: `${action.width}%` }}>
              <Box display="none">
                {action.title}
              </Box>
            </TableCell>
          ))
        }
      </TableRow>
    </TableHead>
  );
}

CustomTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      sorting: PropTypes.bool,
      type: PropTypes.string.isRequired
    })
  ).isRequired,
};

export default CustomTableHead;
