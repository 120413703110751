import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from "components/Common/Dropdown";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import { Table } from "components/Common/Table";
import { TablePagination } from "components/Common/Pagination";
import { postRequest, getRequest, getPlatformXrefRequest } from "api";
import PriceChart from "components/PriceChart/PriceChart";
import { ReactComponent as PDF } from "icons/document-file-pdf.svg";
import "./SearchPage.scss";
import {
  ONE_CLICK_SEARCHES_LABEL,
  ONE_CLICK_SEARCHES_TEXT,
  SEARCH_BUTTON_LABEL,
  ANALYST_RATINGS_TEXT,
  ANALYST_RATINGS_HEADER_TEXT,
  TYPEOFRESEARCH,
  ONE_CLICK_SEARCH,
  GRAPH_SHOW_HIDE,
} from "utils/constants";
import { CustomButton } from "components/Common/Button";
import { lastWeekDate, last7DaysSearchString } from "utils/utilities";
import { formatFullDate } from 'utils/format';
import { Autocomplete } from 'components/Common/Autocomplete';
import { useResearchReport } from 'hooks/useResearchReport';
import { getDocumentPdfURL } from "utils/utilities";

const researchTypeOptions = Object.keys(TYPEOFRESEARCH).map(key => (
  {
    label: TYPEOFRESEARCH[key],
    value: key
  }
))

const defaultResearchType = Object.keys(TYPEOFRESEARCH)[0];

const SearchPage = ({ setSearchAreaheight, defaultTicker }) => {
  const isTickerSelected = useRef(false);
  const [researchFilters, setResearchFilters] = useState(defaultResearchType);
  const [selectedTicker, setSelectedTicker] = useState({});
  const [latestRatingChange, setLatestRatingChange] = useState({});
  const [showRdots, setShowRdots] = useState(true);
  const [oneClickLabel, setOneClickLabel] = useState();
  const [defaultSelectedTicker, setDefaultSelectedTicker] = useState(defaultTicker);
  const [graphRdotToggleText, setGraphRdotToggleText] = useState(true);
  const [chartResearchValue, setChartResearchValue] = useState(defaultResearchType);

  const pdfHandler = async () => {
    const getPayload = () => {
      let str = "IRPID::EQU::506&&";
      if (isTickerSelected.current && selectedTicker && selectedTicker.symbol) {
        str = str + `SYMBOL::EQU::${selectedTicker.symbol}&&`
      }
      return {
        filterString: str,
        rowCount: 1,
        pageNumber: 1,
        sortField: "date",
        sortOrder: "desc",
      };
    };
    const response = await postRequest("researchreport", getPayload());
    const documentKey = response.data.data[0].documentKey;
    return getDocumentPdfURL(documentKey);
  }

  const searchQueryString = () => {
    let str = "IRPID::EQU::506&&";
    if (isTickerSelected.current && selectedTicker && selectedTicker.symbol) {
      str = str + `SYMBOL::EQU::${selectedTicker.symbol}&&`
    }
    if (researchFilters && researchFilters.length && researchFilters !== defaultResearchType) {
      str = str + `TYPEOFRESEARCH::EQU::${researchFilters}&&`
    }
    return str.slice(0, -2);
  }

  const onSymbolClick = (symbolXrefInfo) => {
    const ticker = {
      label: `${symbolXrefInfo.symbol} (${symbolXrefInfo.name})`,
      value: symbolXrefInfo
    }
    setDefaultSelectedTicker(ticker);

  }

  const {
    columns,
    isLoading,
    researchReport,
    message,
    totalRecords,
    currentPageNumber,
    sortField,
    sortOrder,
    recordsPerPage,
    getResearchReport,
    onPageChange,
    onSorting,
  } = useResearchReport(searchQueryString, onSymbolClick);

  setSearchAreaheight(450);

  useEffect(() => {
    if (defaultTicker === null) {
      let last7DaysSearch = last7DaysSearchString();
      getResearchReport(last7DaysSearch);
    }
  }, []);

  useEffect(() => {
    if (selectedTicker.symbol) {
      setOneClickLabel("");
      getResearchReport();
    }
  }, [selectedTicker])

  useEffect(() => {
    onTickerSelect(defaultSelectedTicker);
  }, [defaultSelectedTicker])

  const onTickerSelect = async (item) => {
    if (item) {
      isTickerSelected.current = true;
      setSelectedTicker({ ...item.value })
      if (item?.value?.xids?.venue) {
        const result = await getRequest(
          `/latestRating/?wsodIssue=${item.value.xids.venue.toString()}`
        );
        if (result?.data?.data?.result) {
          setLatestRatingChange({ ...result.data.data.result })
        }
      }
    }
    else {
      isTickerSelected.current = false;
    }
  }

  const onInputChange = async (inputValue) => {
    const result = await getPlatformXrefRequest(`venues?term=${inputValue}`);
    const options = result.data.data.items.map(i => ({ value: i, label: `${i.symbol} (${i.name})` }));
    return options;
  }

  const handleOneSearchClick = async (name) => {
    // Reset the selected filters
    setResearchFilters(defaultResearchType);
    setSelectedTicker({});
    isTickerSelected.current = false;

    //Update Table with one click search
    let searchFilter = ONE_CLICK_SEARCH[name];
    setOneClickLabel(ONE_CLICK_SEARCHES_LABEL[name]);
    if (name === "MOST_RECENT_RESEARCH") {
      const last7daysDate = lastWeekDate();
      let result = searchFilter.replace("{currentDate}", last7daysDate);
      searchFilter = result;
      searchFilter.replace("{currentDate}", lastWeekDate())
    }
    await getResearchReport(searchFilter);
  }

  const handleSearchButton = async () => {
    setChartResearchValue(researchFilters);
    setOneClickLabel("");
    if (!isTickerSelected.current) {
      setSelectedTicker({});
    }
    const searchFilter = searchQueryString();
    await getResearchReport(searchFilter);
  }

  const toggleRDots = () => {
    const allDots = document.querySelectorAll(`.event-icon.eventHover`);
    if (allDots.length !== 0 && showRdots) {
      allDots.forEach((el) => el.style.visibility = "hidden");
    }
    else if (allDots.length !== 0 && !showRdots) {
      allDots.forEach((el) => el.style.visibility = "visible");
    }
  };

  const GRAPH_TOGGLE_TEXT = showRdots ? GRAPH_SHOW_HIDE.HIDE : GRAPH_SHOW_HIDE.SHOW

  const handleRdot = () => {
    GRAPH_TOGGLE_TEXT === "SHOW" ? setShowRdots(true) : setShowRdots(false)
    toggleRDots();
  }
  return (
    <div className="searchPage">
      <div className="searchPage__wrapper">
        <div className="searchPage__container">
          <div className="searchPage__dropdown">
            <div className="searchPage__searchBox">
              <Autocomplete
                onSelect={onTickerSelect}
                onInputChange={onInputChange}
                placeholder="Enter Ticker or Company Name"
                label="Search"
                showValueOnSelect={true}
                selectedValues={selectedTicker}
                defaultValue={defaultSelectedTicker}
              />
            </div>
            <div className="searchPage__researchType">
              <Dropdown
                placeholder={"Select a Research Type"}
                options={researchTypeOptions}
                label={"Research Type"}
                onSelect={(value) => setResearchFilters(value)}
                selectedValues={[researchFilters]}
                showValueOnSelect
              />
            </div>
          </div>
          <CustomButton
            label={SEARCH_BUTTON_LABEL}
            variant="outlined"
            size="medium"
            className="searchPage__searchButton"
            onClick={() => handleSearchButton()}
          ></CustomButton>
          <Box className="searchPage__oneClickSearchBox">
            <div className="searchPage__oneClickSearchText">
              {ONE_CLICK_SEARCHES_TEXT}
            </div>
            <Stack
              direction="row"
              spacing={2}
              divider={
                <Divider
                  className="searchPage__oneClickDivider"
                  variant="fullWidth"
                  orientation="vertical"
                />
              }
              className="searchPage__oneClickStack"
            >
              {
                Object.keys(ONE_CLICK_SEARCHES_LABEL).map(key => (
                  <Link
                    onClick={() => handleOneSearchClick(key)}
                    rel="noopener"
                    aria-label={`Search ${ONE_CLICK_SEARCHES_LABEL[key]}`}
                    className={ONE_CLICK_SEARCHES_LABEL[key] === oneClickLabel && "searchPage__onClickColor"}
                  >
                    {ONE_CLICK_SEARCHES_LABEL[key]}
                  </Link>
                ))
              }
            </Stack>
          </Box>
        </div>
      </div>
      {
        selectedTicker?.xids?.venue && <>
          <div className="searchPage__researchRatingHeader"> {ANALYST_RATINGS_HEADER_TEXT} {selectedTicker.name}
          </div>
          <Box className="searchPage__researchRatingContainer">
            <div className="searchPage__researchRatingText">
              {ANALYST_RATINGS_TEXT} {(latestRatingChange?.rating && latestRatingChange?.ratingDate) ?
                <div>
                  <span className="searchPage__researchRatingData">  {latestRatingChange.rating} </span> since
                  <span className="searchPage__researchRatingData"> {formatFullDate(new Date(latestRatingChange.ratingDate))} </span>
                </div> :
                <div>
                  <span className="searchPage__researchRatingData">Not Rated</span>
                </div>}
            </div>
            <Stack
              direction="row"
              spacing={3}
              divider={
                <Divider
                  className="searchPage__researchRatingDivider"
                  variant="fullWidth"
                  orientation="vertical"
                />
              }
              className="searchPage__researchRatingStack"
            >
              {researchReport.length ? (<Link className="searchPage__researchRatingHeader__pdfLink" onClick={async () => window.open(await pdfHandler(), "_blank")} ><PDF /></Link>) : null}
              <Link
                className="searchPage__researchRatingLink"
                onClick={() => handleRdot()}
                rel="noopener"
                aria-label="Open Conferences in a new window."
              >
                {graphRdotToggleText && GRAPH_TOGGLE_TEXT}
              </Link>
            </Stack>
          </Box>
          <div >
            <PriceChart researchType={chartResearchValue} setGraphRdotToggleText={setGraphRdotToggleText} symbol={selectedTicker.xids.venue.toString()} accessToken={localStorage.getItem('access_token')} />
          </div>
        </>
      }
      <div className="searchPage__result">
        <Table
          isLoading={isLoading}
          title={oneClickLabel || "Recent Research"}
          columns={columns}
          data={researchReport}
          onSorting={onSorting}
          defaultSortOrder={sortOrder.current}
          defaultOrderField={sortField.current}
          errorMessage={message}
        />
        {totalRecords.current > 0 &&
          <TablePagination dataCount={totalRecords.current} rowsPerPage={recordsPerPage} onChange={onPageChange} currentPage={currentPageNumber} />
        }
      </div>
    </div >
  );
};

export default SearchPage;
