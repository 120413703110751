import React, { useState, useEffect } from "react";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from "@mui/material/Box";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./Dropdown.scss";

const Placeholder = ({ children }) => {
  return <div className="placeholder">{children}</div>;
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      maxWidth: 282
    },
  },
};


const Dropdown = (
  { label,
    placeholder,
    showValueOnSelect,
    options,
    onSelect,
    selectedValues,
    containerClassName
  }) => {
  const defaultSelectedValue = showValueOnSelect && selectedValues.length > 0 ? selectedValues[0] : "";
  const [value, setValue] = useState(defaultSelectedValue);
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    if (onSelect) {
      onSelect(selectedValue);
    }
  };

  useEffect(() => {
    const selectedValue = showValueOnSelect && selectedValues.length > 0 ? selectedValues[0] : "";
    if (showValueOnSelect) {
      setValue(selectedValue);
    }
  }, [selectedValues, showValueOnSelect]);


  return (
    <div className={`dropdown ${containerClassName}`}>
      <label className="dropdown__label">{label}</label>
      <Box className="dropdown__wrapper">
        <Select
          variant="outlined"
          value={value}
          onChange={handleChange}
          fullWidth
          IconComponent={ExpandMoreIcon}
          MenuProps={MenuProps}
          displayEmpty
          renderValue={
            value !== "" ? undefined : () => <Placeholder>{placeholder}</Placeholder>
          }
        >
          {
            options.map(option => (
              <MenuItem value={option.value} disabled={selectedValues.includes(option.value)}>
                {option.label}
              </MenuItem>
            ))
          }
        </Select>
      </Box>

    </div>
  )
}

export default Dropdown;
