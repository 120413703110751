import { DataType } from "./consts";
const ratingOrder = {
  UNDERWEIGHT: 0,
  NEUTRAL: 1,
  OVERWEIGHT: 2,
};
const mockObj = {
  title: "Recent Research",
  columns: [
    {
      title: "Date/Time",
      field: "dateTime",
      sorting: true,
      width: 17,
      type: DataType.STRING,
    },
    {
      title: "Company Name",
      field: "companyName",
      sorting: true,
      width: 23.5,
      type: DataType.STRING,
    },
    {
      title: "Headling",
      field: "headline",
      sorting: true,
      width: 44.5,
      type: DataType.ANCHOR,
    },
    {
      title: "Rating",
      field: "rating",
      sorting: true,
      width: 15,
      type: DataType.STRING,
      customSort: (a, b, order) => {
        let sortValue = 0;
        const item1Order = ratingOrder[a.rating.toUpperCase()];
        const item2Order = ratingOrder[b.rating.toUpperCase()];
        if (item1Order < item2Order) {
          sortValue = -1;
        } else if (item1Order > item2Order) {
          sortValue = 1;
        }
        return order === "asc" ? sortValue : -sortValue;
      },
    },
  ],
  data: [
    {
      dateTime: "July 20,2022 11:22 AM",
      companyName: "National Bankshares Inc (NKSH)",
      headline:
        "2Q22 Earnings Review: Shares Remain Punitively Discounted; Reiterate OW",
      rating: "Overweight",
    },
    {
      dateTime: "July 20,2022 11:22 AM",
      companyName: "Biogen Inc (BIIB)",
      headline:
        "Beat, Raise is Nice but this Ship is Adrift, Heading Toward Rough Waters",
      rating: "Neutral",
    },
    {
      dateTime: "July 20,2022 11:22 AM",
      companyName: "L Brands Inc (BBWI)",
      headline:
        "Pre-Release Makes Good Buying Opportunity.; No One's Immune, But Some Better Positioned",
      rating: "Underweight",
    },
    {
      dateTime: "July 20,2022 11:22 AM",
      companyName: "Berkshire Hills Bancorp Inc (BHLB)",
      headline:
        "2Q22 Earnings Review: Making Solid Progress on its &#8220;BEST&#8221; Journey",
      rating: "Overweight",
    },
    {
      dateTime: "July 20,2022 11:22 AM",
      companyName: "National Bankshares Inc (NKSH)",
      headline:
        "2Q22 Earnings Review: Shares Remain Punitively Discounted; Reiterate OW",
      rating: "Overweight",
    },
    {
      dateTime: "July 20,2022 11:22 AM",
      companyName: "Biogen Inc (BIIB)",
      headline:
        "Beat, Raise is Nice but this Ship is Adrift, Heading Toward Rough Waters",
      rating: "Neutral",
    },
    {
      dateTime: "July 20,2022 11:22 AM",
      companyName: "L Brands Inc (BBWI)",
      headline:
        "Pre-Release Makes Good Buying Opportunity.; No One's Immune, But Some Better Positioned",
      rating: "Underweight",
    },
    {
      dateTime: "July 20,2022 11:22 AM",
      companyName: "Berkshire Hills Bancorp Inc (BHLB)",
      headline:
        "2Q22 Earnings Review: Making Solid Progress on its &#8220;BEST&#8221; Journey",
      rating: "Overweight",
    },
  ],
};

export default mockObj;
