import React from "react";
import PropTypes from "prop-types";
import "./PriceChart.scss";
import { ModchartsInteractive } from "@markit/modcharts";
import CircularProgress from "@mui/material/CircularProgress";
import { getRequest } from "api";
import { formatShortDate } from 'utils/format';
const chart = (window.chart = new ModchartsInteractive());

class PriceChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaderState: false,
      symbol: "",
      researchType: "",
    };
  }

  static propTypes = {
    accessToken: PropTypes.string,
    symbol: PropTypes.string
  };

  shouldComponentUpdate(prevProps, prevState) {
    return prevState.loaderState !== this.state.loaderState;
  }
  componentDidMount() {
    this.getChart();
    this.addRatingsEvents();
    chart.eventEmitter.on("DATA_LOAD_STOP", this.disableLoader);
  }
  componentDidUpdate() {
    this.getChart();
    this.addRatingsEvents();
    chart.eventEmitter.on("DATA_LOAD_STOP", this.disableLoader);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.symbol !== state.symbol || props.researchType !== state.researchType) {
      return {
        symbol: props.symbol,
        researchType: props.researchType,
        loaderState: true
      };
    }
    return null; // No change to state
  }

  disableLoader = () => {
    this.setState({ loaderState: false });
  };
  getChart() {
    chart.mount("#modchartsPriceChart");
    chart.setAuthToken(this.props.accessToken);
    chart.load({
      params: {
        apiPath: window.MD.chartAPIPath,
        symbol: this.props.symbol,
        days: 1095,  // Three Years Data (365 * 3)
        dataPeriod: "Week",
        lastClose: false,
        showFlags: false,
        zoomEnabled: false,
        style: {
          panel: {
            grid: {
              color: "transparent",
              horizontal: {
                color: "#d9d9d9",
                lineDash: "",
                width: "1",
              },
              vertical: {
                alt: { color: "#d9d9d9", lineDash: "0" },
              },
            },
            border: {
              default: { color: "transparent" },
              bottom: { color: "#C8C8C8" },
            },
            yAxis: {
              padding: {
                bottom: 40,
                top: 70
              },
              label: {
                color: "#4A4F55",
                format: {
                  default: { format: "$0,.0f", formatPercent: "0,.0%" },
                },
                padding: {
                  right: 0,
                },
                textBaseline: "middle",
              },
              font: {
                family: "Cairo Regular",
                size: 14,
                weight: "normal",
              },
            },
          },
        }
      },
      panels: [
        {
          indicators: [
            {
              id: "price",
              markerType: "fill",
              style: {
                color: "#3F97B5",
                background: {
                  color: "#3f97b533",
                  colorStop: "#3f97b533",
                },
              },
            },
          ],
        },
      ],
    });
  }
  async addRatingsEvents() {
    this.removeExistingEvents();
    if (this.props.symbol) {
      const result = await getRequest(
        `/priceChart/?wsodIssue=${this.props.symbol.toString()}&researchType=${this.props.researchType}`
      );
      if (result?.data?.data?.result?.data) {
        const ratingsData = this.transformData(result.data.data.result.data);
        if (!ratingsData.length) {
          this.props.setGraphRdotToggleText(false);
        }
        else {
          this.props.setGraphRdotToggleText(true);
        }
        chart.panels[0].addEvent("custom", {
          name: "RatingsData",
          dataset: ratingsData,
        });
        chart.eventEmitter.on("EVENT_RENDER", this.customEventIcon);
      }
    }
  }
  transformData(ratingData) {
    let dataset = [];
    if (ratingData) {
      ratingData.forEach((result) => {
        const res = {
          documentKey: result.documentKey,
          headline: result.headline,
          date: result.documentDate,
          rating: result.rating
        };
        dataset.push(res);
      });
      return dataset;
    }
  }
  customEventIcon = (event) => {
    event.coords.forEach((c, i) => {
      const id = `rating-icon-${i}`;
      let el = document.getElementById(id);
      if (!el) {
        el = document.createElement("div");
        el.className = `event-icon eventHover`;
        el.id = id;
        el.style.position = "absolute";
        el.setAttribute("tabindex", 0);
        chart.rootMouse.node().appendChild(el);
      }
      el.style.left = `${c.x - 10}px`;
      el.style.top = `${c.y - 22}px`;
      el.style.visibility = "visible";
      document.getElementById(`rating-icon-${i}`).addEventListener("mouseenter", (e) => this.updateTooltip(e.currentTarget.id, c));
      document.getElementById(`rating-icon-${i}`).addEventListener("mouseleave", (e) => this.hideTooltip(e.currentTarget.id));
    });
  };
  removeExistingEvents() {
    if (document.querySelectorAll(`.event-icon.eventHover`).length !== 0) {
      document.querySelectorAll(`.event-icon.eventHover`).forEach((el) => el.remove());
    }
  };
  updateTooltip = (divId, eventData) => {
    let eventEl = document.getElementById(divId)
    if (document.querySelectorAll(`div.eventTooltip`).length !== 0) {
      document.querySelectorAll(`div.eventTooltip`).forEach((el) => el.remove());
    }
    eventEl.appendChild(document.createElement("div")).setAttribute("class", "eventTooltip");
    //position tooltip on extreme left and right of chart.
    this.positionTooltip(eventEl, eventData.x)
    eventEl.querySelector(`#${divId} .eventTooltip`).appendChild(document.createElement("div")).setAttribute("class", "header");
    eventEl.querySelector(`#${divId} .eventTooltip div.header`).innerText = 'Piper Sandler Research';
    eventEl.querySelector(`#${divId} .eventTooltip`).appendChild(document.createElement("div")).setAttribute("class", "eventContent");
    addRow("Date", eventData?.event?.date ? formatShortDate(new Date(eventData.event.date)) : '--', 0);
    addRow("Rating", eventData?.event?.rating ? eventData.event.rating : '--', 1);
    function addRow(label, val, i) {
      if (eventEl.querySelectorAll("tr:nth-child(" + (i + 1) + ")").length === 0) {
        eventEl.querySelector(".eventContent").appendChild(document.createElement("tr")).setAttribute("class", "row");
      }
      if (eventEl.querySelectorAll("tr:nth-child(" + (i + 1) + ")").length !== 0 && eventEl.querySelectorAll("tr:nth-child(" + (i + 1) + ")")[0].childElementCount !== 0) {
        eventEl.querySelectorAll(".eventlabel")[i].innerText = label;
        eventEl.querySelectorAll(".eventval")[i].innerText = val !== undefined ? val : "--";
      } else {
        eventEl.querySelectorAll("tr.row")[i].appendChild(document.createElement("td")).setAttribute("class", "eventlabel");
        eventEl.querySelectorAll(".eventlabel")[i].innerText = label;
        eventEl.querySelectorAll("tr.row")[i].appendChild(document.createElement("td")).setAttribute("class", "eventval");
        eventEl.querySelectorAll(".eventval")[i].innerText = val !== undefined ? val : "--";
      }
    }
  };
  positionTooltip = (eventEl, x) => {
    if ((60 > x) && (x < 80)) {
      eventEl.querySelector(".eventTooltip").style.right = (x + 10) + "px";
    }
    if (x > 1080) {
      eventEl.querySelector(".eventTooltip").style.right = (x - 1010) + "px";
    }

  }
  hideTooltip = (divId) => {
    if (document.querySelectorAll(`#${divId} .eventTooltip`).length !== 0) {
      document.querySelector(`#${divId} .eventTooltip`).style.visibility = "hidden";
    }
  };
  render() {
    return <>{this.state.loaderState && <div className="priceChart__loaderContainer"><CircularProgress className="priceChart__loader" /></div>} <div id="modchartsPriceChart" /></>;
  }
}

export default PriceChart;
