import React, { useState, useEffect, useCallback, useRef } from "react";
import { Table } from "components/Common/Table";
import { concurrentRequests, bulkDeleteRequest } from "api";
import "./SavedSearch.scss";
import { DataType } from 'components/Common/Table/consts';
import { formatDate } from 'utils/format';
import { generateSearchDescription } from 'utils/utilities';
import { CustomDialog } from "components/Common/CustomDialog";

const columns = [
  {
    title: "Name",
    field: "queryName",
    sorting: false,
    width: 23,
    type: DataType.SELECT,
  },
  {
    title: "Criteria",
    field: "searchParams",
    sorting: false,
    width: 35,
    type: DataType.STRING,
  },
  {
    title: "Created",
    field: "dateCreated",
    sorting: false,
    width: 18,
    type: DataType.STRING,
  },
  {
    title: "Alerts",
    field: "isAlertable",
    sorting: false,
    width: 8,
    type: DataType.STRING,
  },
]

export default function SavedSearch({ setSearchAreaheight, onEdit, onRun }) {
  setSearchAreaheight(240);

  const [savedSearches, setSavedSearches] = useState([]);
  const [industriesData, setIndustriesData] = useState([]);
  const [handleTableloading, setHandleTableloading] = useState({ isLoading: true, errorMessage: 'No Saved Searches.' });
  const [dialogState, setDialogState] = useState(false);
  const [confirmationDialogMsg, setConfirmationDialogMsg] = useState("");
  const selectedRowIdsRef = useRef([]);
  const resolvePromise = useRef(null);
  const rejectPromise = useRef(null);

  const actions = [
    {
      title: 'Edit',
      tooltip: 'Edit',
      onClick: (event, rowData) => {
        onEdit(rowData.savedSearchInfo);
      },
      width: 8
    },
    {
      title: 'Run',
      tooltip: 'Run',
      onClick: (event, rowData) => {
        onRun(rowData.savedSearchInfo);
      },
      width: 8
    },
  ];

  const handleConfirmation = useCallback(() => {
    setDialogState(false);
    const selectedRowIds = selectedRowIdsRef.current;
    if (selectedRowIds && selectedRowIds.length > 0) {
      setHandleTableloading(props => ({ ...props, isLoading: true }));
      let urls = [];
      let savedSearchesData = [...savedSearches];
      selectedRowIds.forEach((r) => {
        const search = savedSearchesData.find(({ QueryName }) => QueryName === r);
        if (search) {
          urls.push(`SavedSearch?savedSearchName=${search.QueryName}`)
        }
      })
      bulkDeleteRequest(urls).then(() => {
        //update state
        setSavedSearches(savedSearchesData.filter(({ QueryName }) => !selectedRowIds.includes(QueryName)));
        setHandleTableloading(props => ({ ...props, isLoading: false }));
        setDialogState(false);
        if (resolvePromise.current) {
          resolvePromise.current();
          resolvePromise.current = null;
        }
      }
      );
    }
  }, [savedSearches]);

  const handleOnClose = useCallback(() => {
    setDialogState(false);
    if (rejectPromise.current) {
      rejectPromise.current();
      rejectPromise.current = null;
    }
  }, []);

  const bulkSelectionAction = [
    {
      title: 'Delete',
      tooltip: 'Delete',
      onClick: (event, selectedRowIds) => {
        const promise = new Promise((resolve, reject) => {
          selectedRowIdsRef.current = selectedRowIds;
          resolvePromise.current = resolve;
          rejectPromise.current = reject;
        });
        const searchName = selectedRowIds[0];
        const dialogMessage = <>Are you sure you want to delete <strong>"{searchName}"</strong> ?</>
        selectedRowIds.length === 1 ? setConfirmationDialogMsg(dialogMessage) : setConfirmationDialogMsg("Are you sure you want to delete selected saved searches?")
        setDialogState(true);
        return promise;
      },
    }
  ]

  const prepareData = () => {
    let tableData = [];
    let data = savedSearches;
    if (data && data.length > 0) {
      tableData = data.map(search => ({
        id: search.QueryName,
        queryName: search.QueryName,
        searchParams: generateSearchDescription(search.QueryAttributes.SearchParams.replaceAll("\"", ""), industriesData).split('<br />').map(line => {
          return <React.Fragment>{line}<br /></React.Fragment>
        }),
        dateCreated: formatDate(new Date(search.DateCreated)),
        isAlertable: search.IsAlertable ? "Active" : "None",
        savedSearchInfo: JSON.parse(JSON.stringify(search))
      }))
    }
    return tableData;
  }

  const getSavedSearches = async () => {
    try {
      const result = await concurrentRequests(['SavedSearch', 'search-criteria-fields/?providerId=506&type=industry']);
      const savedSearchesData = result[0];
      const industryData = result[1]?.data?.data?.industries || [];
      setIndustriesData(industryData);
      if (savedSearchesData.data.data && savedSearchesData.data.data.length > 0) {
        setSavedSearches(savedSearchesData.data.data)
      }
      setHandleTableloading(props => ({ ...props, isLoading: false }));
    }
    catch (error) {
      setHandleTableloading(props => ({ ...props, isLoading: false, errorMessage: "Something went wrong" }));
      console.log(error);
    }
  }

  useEffect(() => {
    getSavedSearches()
  }, []);

  return (
    <div className="savedSearchPage">
      <div className="savedSearchPage__table">
        <Table
          isLoading={handleTableloading.isLoading}
          title="Saved Searches"
          columns={columns}
          data={prepareData()}
          actions={actions}
          bulkSelectionAction={bulkSelectionAction}
          errorMessage={handleTableloading.errorMessage}
        />
        <CustomDialog onConfirm={handleConfirmation} onClose={handleOnClose} dialogState={dialogState} title="Delete Saved Search" cancelButtonTxt="CANCEL" deleteButtonTxt="YES DELETE" dialogContent={confirmationDialogMsg} />
      </div>
    </div>
  );
}
