import Checkbox from '@mui/material/Checkbox';
import "./Checkbox.scss";

const InputIcon = (props) => {
  return (
    <span className='checkbox__icon' {...props} />
  )
}

const CheckedInputIcon = (props) => {
  return (
    <span className='checkbox__icon checkbox__icon--checked' {...props} />
  )
}

const CustomCheckbox = (props) => {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={ <CheckedInputIcon /> }
      icon={<InputIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  )
}

export default CustomCheckbox;