// format date in MM/DD/YYYY
export const formatDate = (date) => {
  return [
    date.getMonth() + 1,
    date.getDate(),
    date.getFullYear(),
  ].join('/');
};
//format date in month date, yyyy
export const formatFullDate = (date) => {
  return date.toLocaleDateString({},
    {month:"long", day:"numeric", year:"numeric"}
    )
};
//format date in mon date, yyyy
export const formatShortDate = (date) => {
  return date.toLocaleDateString({},
    {month:"short", day:"numeric", year:"numeric"}
    )
};