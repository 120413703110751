import { React, useState } from "react";
import { Tabs, Box, Tab } from "@mui/material";
import { Container } from "@mui/system";
import { EQUITY_RESEARCH, TABS_LABEL } from "utils/constants";
import Typography from "@mui/material/Typography";
import "./TabsPage.scss";
import { SavedSearch } from "components/SavedSearch";
import AdvancedSearch from "components/AdvancedSearch/AdvancedSearch";
import { SearchPage } from "components/SearchPage";

let savedSearchInfo = null;
let searchCriteria = null;
let defaultTicker = null;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabsPage = () => {
  const [value, setValue] = useState(0);
  const [searchAreaheight, setSearchAreaheight] = useState(0);

  const handleChange = (event, newValue) => {
    savedSearchInfo = null;
    searchCriteria = null;
    defaultTicker = null;
    setValue(newValue);
  };

  const editSavedSearch = (savedSearch) => {
    savedSearchInfo = savedSearch;
    searchCriteria = savedSearch.QueryAttributes.SearchParams
    setValue(1); // open advance search
    window['scrollTo']({ top: 0, behavior: 'smooth' })
  }

  const runSavedSearch = (savedSearch) => {
    savedSearchInfo = null;
    searchCriteria = savedSearch.QueryAttributes.SearchParams
    setValue(1); // open advance search
    window['scrollTo']({ top: 0, behavior: 'smooth' })
  }

  const onCompanyNameClick = (ticker) => {
    defaultTicker = ticker;
    setValue(0); // open basic search
    window['scrollTo']({ top: 0, behavior: 'smooth' })
  }

  return (
    <div>
      <Box sx={{ flexGrow: 1, height: searchAreaheight }} className="tabs__box">
        <div className="tabs__heading">{EQUITY_RESEARCH}</div>
        <Container className="tabs__container">
          <Tabs
            className="tabs__search-tabs"
            value={value}
            onChange={handleChange}
            textColor="#4A4F55"
            TabIndicatorProps={{
              style: {
                background: "#ff5d00",
                height: "4px",
                marginBottom: "8px",
              },
            }}
            aria-label="search tabs"
          >
            <Tab
              className="tabs__tab"
              label={TABS_LABEL.SEARCH}
              {...a11yProps(0)}
            />
            <Tab
              className="tabs__tab"
              label={TABS_LABEL.ADVANCED_SEARCH}
              {...a11yProps(1)}
            />
            <Tab
              className="tabs__tab"
              label={TABS_LABEL.SAVED_SEARCHES}
              {...a11yProps(2)}
            />
          </Tabs>
        </Container>
      </Box>
      <Container className="tabs__container">
        <TabPanel
          className="tabPanel__searchTabPanel"
          value={value}
          index={0}
        >
          <SearchPage setSearchAreaheight={setSearchAreaheight} defaultTicker={defaultTicker} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AdvancedSearch setSearchAreaheight={setSearchAreaheight} searchCriteria={searchCriteria} savedSearchInfo={savedSearchInfo} onCompanyNameClick={onCompanyNameClick} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SavedSearch setSearchAreaheight={setSearchAreaheight} onEdit={editSavedSearch} onRun={runSavedSearch} />
        </TabPanel>
      </Container>
    </div>
  );
};

export default TabsPage;
