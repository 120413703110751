import { Header } from "components/Header";
import { Footer } from "components/Footer";
import { Tabs } from "components/TabsPage";
import "./Layout.scss";


const Layout = () => {
  return (
    <>
      <div className="main-wrapper">
        <Header />
        <main className="main-wrapper__container">
        <Tabs />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
