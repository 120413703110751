import React, { useState, useEffect, useRef } from "react";
import { Table } from "components/Common/Table";
import { TablePagination } from "components/Common/Pagination";
import { Dropdown } from "components/Common/Dropdown";
import { Box, Chip } from "@mui/material";
import "./AdvancedSearch.scss";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { styled } from "@mui/material/styles";
import { CustomButton } from "components/Common/Button";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CustomModal } from "./Modal";
import CloseIcon from "@mui/icons-material/Close";
import { SuccessModal } from "./SuccessModal";
import { DATEPICKER, YOUR_SEARCH_CRITERIA_LABELS, TYPEOFRESEARCH } from "utils/constants";
import { getPlatformXrefRequest, getRequest, postPlatformXrefRequest } from "api";
import { dateOptions } from "utils/constants";
import { Autocomplete } from "components/Common/Autocomplete";
import { selectFixedDuration, last7DaysSearchString } from "utils/utilities";
import { useResearchReport } from 'hooks/useResearchReport';

const researchTypeOptions = Object.keys(TYPEOFRESEARCH).map(key => (
  {
    label: TYPEOFRESEARCH[key],
    value: key
  }
))

const days = {
  MO: "Mon",
  TU: "Tue",
  WE: "Wed",
  TH: "Thu",
  FR: "Fri",
  SA: "Sat",
  SU: "Sun",
};

function AdvancedSearch({ setSearchAreaheight, searchCriteria, savedSearchInfo, onCompanyNameClick }) {
  setSearchAreaheight(612);
  const userEmailID = useRef('');
  const [ticker, setTicker] = useState([]);
  const [researchFilters, setResearchFilters] = useState([]);
  const [industryFilters, setIndustryFilters] = useState([]);
  const [subjectFilters, setSubjectFilters] = useState([]);
  const [dateFilters, setDateFilters] = useState([]);
  const [analystFilters, setAnalystFilters] = useState([]);
  const [fromDateValue, setFromDateValue] = useState("");
  const [toDateValue, setToDateValue] = useState("");
  const [keywordValue, setKeywordValue] = useState("");
  const [keywordArray, setKeywordArray] = useState([]);
  const [enableSaveSearchModal, setEnableSaveSearchModal] = useState(false);
  const [successModalState, setSuccessModalState] = useState(false);
  const [errorModalState, setErrorModalState] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Something Went Wrong');
  const [analystOptions, setAnalystOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [enableDatePicker, setEnableDatePicker] = useState(true);
  const [initialStateUpdated, setInitialStateUpdated] = useState(false);

  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));
  const handleSaveSearch = async (e) => {
    const payLoad = {
      "symbols": [...ticker]
    }
    let responseItems = [];
    try {
      // handle edit and run flow
      if (searchCriteria !== null && ticker.length > 0) {
        const result = await postPlatformXrefRequest('symbols', payLoad);
        if (result?.data?.data?.items) {
          responseItems = [...result.data.data.items];
        }
      }
    }
    catch (ex) {
      if (ex?.response?.data?.data?.items) {
        responseItems = [...ex.response.data.data.items];
      }
    }
    const invalidSymbols = responseItems.reduce((prev, cur) => {
      if (cur.error && cur.error.status === 404) {
        prev.push(cur.error.inputData.symbol)
      }
      return prev;
    }, [])

    if (invalidSymbols && invalidSymbols.length > 0) {
      let errorMsgToShow = '';
      if (invalidSymbols.length > 1) {
        errorMsgToShow = `${invalidSymbols.join(", ")} symbols have been delisted. Please modify your search criteria and try again.`;
      } else {
        errorMsgToShow = `${invalidSymbols.join(",")} symbol has been delisted. Please modify your search criteria and try again.`;
      }
      setErrorMsg(errorMsgToShow);
      setErrorModalState(true);
    }
    else {
      setEnableSaveSearchModal(true);
    }
  };

  const searchQueryString = () => {
    const filtersEnum = {
      "SYMBOL": ticker,
      "TYPEOFRESEARCH": researchFilters,
      "INDUSTRYCODES": industryFilters,
      "SUBJECT": subjectFilters,
      "DocumentDate": dateFilters,
      "AUTHORS": analystFilters,
      "fromDateValue": fromDateValue,
      "toDateValue": toDateValue,
      "HEADLINE": keywordArray,
    };

    let filterKeys = Object.keys(filtersEnum);
    let str = "IRPID::EQU::506&&";

    filterKeys.forEach((key) => {
      const selectedValues = filtersEnum[key];

      switch (key) {
        case "SYMBOL":
          if (selectedValues.length !== 0) {
            let symbolValues = selectedValues.map((element) => {
              return element.symbol
            })
            str = str + `${key}::EQU::${symbolValues.join("||")}&&`;
          }
          break;

        case "DocumentDate":
          if (selectedValues.length !== 0 && selectedValues != "Custom" && selectedValues[0] !== "Anytime") {
            let gmtDate = selectFixedDuration(selectedValues);
            str = str + `DocumentDate::GEQ::${gmtDate}&&`;
          }
          break;

        case "toDateValue":
          if (selectedValues)
            str =
              str + `DocumentDate::LEQ::${selectedValues}&&`;
          break;

        case "fromDateValue":
          if (selectedValues)
            str = str + `DocumentDate::GEQ::${selectedValues}&&`;
          break;

        default:
          if (selectedValues.length !== 0)
            str = str + `${key}::EQU::${selectedValues.join("||")}&&`;
          break;
      }
    });

    const queryString = str.slice(0, -2);
    return queryString;
  };

  const onSymbolClick = (symbolXrefInfo) => {
    const ticker = {
      label: `${symbolXrefInfo.symbol} (${symbolXrefInfo.name})`,
      value: symbolXrefInfo
    }
    onCompanyNameClick(ticker);
  }

  const {
    columns,
    isLoading,
    researchReport,
    message,
    totalRecords,
    currentPageNumber,
    sortField,
    sortOrder,
    recordsPerPage,
    getResearchReport,
    onPageChange,
    onSorting,
  } = useResearchReport(searchQueryString, onSymbolClick);

  useEffect(() => {
    if (dateFilters == "Custom") {
      setEnableDatePicker(false);
    } else {
      setEnableDatePicker(true);
      setToDateValue(null);
      setFromDateValue(null);
    };
  }, [dateFilters]);

  useEffect(() => {
    async function fetchUser() {
      const response = await getRequest('Alerts');
      if (response.status) {
        const { emailID } = response.data.data;
        userEmailID.current = emailID;
      }
    }
    fetchUser();
  }, [])

  useEffect(() => {
    if (searchCriteria === null) {
      let last7DaysSearch = last7DaysSearchString();
      getResearchReport(last7DaysSearch);
    }

    const dropdownApiRequest = async () => {
      const result = await getRequest(
        `/search-criteria-fields/?providerId=506`
      );
      return result.data.data;
    };
    dropdownApiRequest().then((data) => {
      dropdownApiHandler(data);
    });
  }, []);

  useEffect(() => {
    if (initialStateUpdated && searchCriteria !== null && savedSearchInfo === null) {
      getResearchReport(); // run flow
    }
  }, [initialStateUpdated])

  const handleKeyWordTextBox = (e) => {
    if (!keywordArray.includes(keywordValue))
      setKeywordArray([...keywordArray, keywordValue]);
    setKeywordValue("");
  };
  function customDateobj(date, value, label) {
    let currentDate = new Date(date);
    let formatedDate = currentDate.toLocaleDateString({}, { month: "long", day: "numeric", year: "numeric" });
    if (value !== "" && dateFilters == "Custom")
      return {
        type: value,
        label: `${label} Date: ${formatedDate}`,
      };
    return "";
  }
  const ChipsArray = (props) => {
    const searchCriteriaOptions = [
      ...ticker.map((item) => {
        return {
          type: "ticker",
          label: `Ticker: ${item.symbol}`,
          value: item,
        };
      }),
      ...analystFilters.map((item) => {
        return {
          type: "analyst",
          label: `Analyst: ${item}`,
          value: item,
        };
      }),
      ...industryFilters.map((item) => {
        const industryValue = industryOptions.find(
          (industry) => industry.value === item
        );
        return {
          type: "industry",
          label: `Industry Type: ${industryValue.label}`,
          value: item,
        };
      }),
      ...subjectFilters.map((item) => {
        return {
          type: "subject",
          label: `Subject: ${item}`,
          value: item,
        };
      }),
      ...researchFilters.map((item) => {
        return {
          type: "research",
          label: `Research Type: ${TYPEOFRESEARCH[item]}`,
          value: item,
        };
      }),
      ...dateFilters.map((item) => {
        if (item !== "Custom") {
          return {
            type: "date",
            label: `Date: ${item}`,
            value: item,
          };
        } else return "";
      }),
      fromDateValue !== "" && fromDateValue !== null
        ? customDateobj(fromDateValue, "fromDateValue", "From")
        : "",
      toDateValue !== "" && toDateValue !== null
        ? customDateobj(toDateValue, "toDateValue", "To")
        : "",
      ...keywordArray.map((item) => {
        return {
          type: "keyWord",
          label: `Key Word: ${item}`,
          value: item,
        };
      }),
    ];

    const consolidatedArray = searchCriteriaOptions.filter((obj) => {
      if (Object.keys(obj).length !== 0) {
        return obj;
      } else return null;
    });

    const handleDelete = (data) => () => {
      switch (data.type) {
        case "research":
          setResearchFilters(
            researchFilters.filter((dataItem) => dataItem !== data.value)
          );
          break;
        case "industry":
          setIndustryFilters(
            industryFilters.filter((dataItem) => dataItem !== data.value)
          );
          break;
        case "analyst":
          setAnalystFilters(
            analystFilters.filter((dataItem) => dataItem !== data.value)
          );
          break;
        case "ticker":
          setTicker(ticker.filter((dataItem) => dataItem !== data.value));
          break;
        case "subject":
          setSubjectFilters(
            subjectFilters.filter((dataItem) => dataItem !== data.value)
          );
          break;
        case "date":
          setDateFilters([]);
          break;
        case "keyWord":
          setKeywordArray(
            keywordArray.filter((dataItem) => dataItem !== data.value)
          );
          break;
        case "fromDateValue":
          setFromDateValue(null);
          break;
        case "toDateValue":
          setToDateValue(null);
          break;
      }
    };

    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          listStyle: "none",
          p: 0.5,
          m: 0,
        }}
        component="ul"
      >
        {consolidatedArray.map((data, index) => {
          let icon;
          return (
            <ListItem key={data.key}>
              <Chip
                className={props.className}
                icon={icon}
                label={data.label}
                onDelete={handleDelete(data)}
                deleteIcon={<CloseIcon style={{ color: "white" }} />}
              />
            </ListItem>
          );
        })}
      </div>
    );
  };

  const onTicketSelect = (item) => {
    setTicker([...ticker, { ...item.value }]);
  };

  const onInputChange = async (inputValue) => {
    const result = await getPlatformXrefRequest(`venues?term=${inputValue}`);
    const options = result.data.data.items.map((i) => ({
      value: i,
      label: `${i.symbol} (${i.name})`,
    }));
    return options;
  };

  const dropdownApiHandler = (data) => {
    const analystArray = data.analysts.map((item) => {
      return { label: item.Author, value: item.Author };
    });
    setAnalystOptions(analystArray);
    const industryArray = data.industries.map((item) => {
      return {
        label: item.Industry,
        value: item.IndustryCode,
        industryCode: item.IndustryCode,
      };
    });
    setIndustryOptions(industryArray);
    const subjectArray = data.subjects.map((item) => {
      return {
        label: item.Subject,
        value: item.Subject,
      };
    });
    setSubjectOptions(subjectArray);
    setInitialStates();
  };

  const setInitialStates = () => {
    const criteriaArray = searchCriteria && searchCriteria.length ? searchCriteria.replaceAll("\"", "").split("&&") : [];
    criteriaArray.forEach(criteria => {
      const rc = criteria.split("::");
      const field = rc[0];
      const operator = rc[1];
      const values = rc[2];
      switch (field) {
        case "SYMBOL":
          const tickersArray = values.split("||").map(ticker => ({
            symbol: ticker
          }));
          setTicker(tickersArray);
          break;
        case "AUTHORS":
          setAnalystFilters(values.split("||"));
          break;
        case "SUBJECT":
          setSubjectFilters(values.split("||"));
          break;
        case "TYPEOFRESEARCH":
          setResearchFilters(values.split("||"));
          break;
        case "INDUSTRYCODES":
          setIndustryFilters(values.split("||"));
          break;
        case "HEADLINE":
          setKeywordArray(values.split("||"));
          break;
        case "DocumentDate":
          if (operator === 'LEQ') {
            setToDateValue(values) // set date as GMT
          }
          if (operator === 'GEQ') {
            setFromDateValue(values) // set date as GMT
          }
          setDateFilters(["Custom"]);
          break;
        default:
          break;
      }
    });

    if (criteriaArray.length > 0) {
      setInitialStateUpdated(true);
    }
  }

  return (
    <div className="advanceSearchPage">
      <div className="advanceSearchPage__wrapper">
        <div className="advanceSearchPage__container">
          <div className="searchBox__container">
            <div className="searchBox__inputbox">
              <Autocomplete
                placeholder={"Enter Ticker or Company Name"}
                label={"Ticker"}
                onSelect={onTicketSelect}
                onInputChange={onInputChange}
                showValueOnSelect={false}
                selectedValues={ticker}
              />
              <Dropdown
                placeholder={"Select an Analyst"}
                options={analystOptions}
                label={"Analyst"}
                onSelect={(value) =>
                  setAnalystFilters([...analystFilters, value])
                }
                selectedValues={analystFilters}
              />
              <Dropdown
                placeholder={"Select an Industry"}
                options={industryOptions}
                label={"Industry"}
                onSelect={(value) =>
                  setIndustryFilters([...industryFilters, value])
                }
                selectedValues={industryFilters}
              />
              <Dropdown
                placeholder={"Select a Subject"}
                options={subjectOptions}
                label={"Subject"}
                onSelect={(value) =>
                  setSubjectFilters([...subjectFilters, value])
                }
                selectedValues={subjectFilters}
              />
              <Dropdown
                placeholder={"Select a Research Type"}
                options={researchTypeOptions.slice(1)}
                label={"Research Type"}
                onSelect={(value) =>
                  setResearchFilters([...researchFilters, value])
                }
                selectedValues={researchFilters}
              />
              <Dropdown
                placeholder={"Select a Date"}
                options={dateOptions}
                label={"Date"}
                onSelect={(value) => setDateFilters([value])}
                selectedValues={dateFilters}
                showValueOnSelect
              />
              <div>
                <label
                  className={`advanceSearchPage__datepickerLabel ${enableDatePicker &&
                    "advanceSearchPage__datepickerLabel--disabled"
                    }`}
                >
                  {DATEPICKER.FROM}
                </label>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled={enableDatePicker}
                      className={`advanceSearchPage__datepickerContainer ${enableDatePicker &&
                        "advanceSearchPage__datepickerContainer__datepickerContainerDisabled"
                        }`}
                      dayOfWeekFormatter={(day) => days[day.toUpperCase()]}
                      sx={{
                        "&.Mui-focused": {
                          borderColor: "transparent",
                        },
                      }}
                      PaperProps={{
                        sx: {
                          "& .MuiPickersDay-root": {
                            "&.Mui-selected": {
                              backgroundColor: "orange",
                              ":hover": { backgroundColor: "orange" },
                            },
                          },
                        },
                      }}
                      PopperProps={{
                        placement: "bottom-start",
                      }}
                      value={fromDateValue}
                      components={{
                        OpenPickerIcon: CalendarMonthIcon,
                        LeftArrowIcon: KeyboardArrowLeftIcon,
                        RightArrowIcon: KeyboardArrowRightIcon,
                        SwitchViewIcon: KeyboardArrowDownIcon,
                      }}
                      onChange={(newValue) => {
                        setFromDateValue(newValue);
                        setToDateValue(null);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div>
                <label
                  className={`advanceSearchPage__datepickerLabel ${enableDatePicker &&
                    "advanceSearchPage__datepickerLabel--disabled"
                    }`}
                >
                  {DATEPICKER.TO}
                </label>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled={enableDatePicker}
                      className={`advanceSearchPage__datepickerContainer ${enableDatePicker &&
                        "advanceSearchPage__datepickerContainer__datepickerContainerDisabled"
                        }`}
                      value={toDateValue}
                      dayOfWeekFormatter={(day) => days[day.toUpperCase()]}
                      components={{
                        OpenPickerIcon: CalendarMonthIcon,
                        LeftArrowIcon: KeyboardArrowLeftIcon,
                        RightArrowIcon: KeyboardArrowRightIcon,
                        SwitchViewIcon: KeyboardArrowDownIcon,
                      }}
                      onChange={(newValue) => {
                        let toDate = new Date(newValue).getTime();
                        let fromDate = new Date(fromDateValue).getTime();
                        toDate < fromDate
                          ? setToDateValue(null)
                          : setToDateValue(newValue);
                      }}
                      PopperProps={{
                        placement: "bottom-start",
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      minDate={fromDateValue}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div>
                <label className="advanceSearchPage__keywordTextBoxLabel">
                  Key Word
                </label>
                <div className="advanceSearchPage__keywordTextBox">
                  <input
                    placeholder="Search Keyword in Headline"
                    className="advanceSearchPage__keywordTextBoxInput"
                    type="text"
                    value={keywordValue}
                    onChange={(e) => {
                      setKeywordValue(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      e.code === "Enter" && handleKeyWordTextBox(e);
                    }}
                  />
                  <CustomButton
                    variant="text"
                    className="advanceSearchPage__keywordCancelButton"
                    label="ADD TO CRITERIA"
                    disableRipple="true"
                    onClick={(e) => {
                      handleKeyWordTextBox(e);
                    }}
                    disabled={!keywordValue}
                  />
                </div>
              </div>
              <div>
                <CustomButton
                  label="SEARCH"
                  className="advanceSearchPage__searchButton"
                  variant="outlined"
                  onClick={() => getResearchReport()}
                ></CustomButton>
                <div className="advanceSearchPage__runOnceText">Run once without saving</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        emailID={userEmailID.current}
        searchName={savedSearchInfo?.QueryName}
        isEmailAlert={savedSearchInfo?.IsAlertable}
        state={enableSaveSearchModal}
        getSearchCriteria={searchQueryString}
        callBackFn={setEnableSaveSearchModal}
        setSuccessModalState={setSuccessModalState}
      />
      <SuccessModal
        state={successModalState}
        setSuccessModalState={setSuccessModalState}
      />
      <SuccessModal
        state={errorModalState}
        setSuccessModalState={setErrorModalState}
        isError={true}
        errorMsg={errorMsg}
      />
      <div className="advanceSearchPage__tableWrapper">
        <Box className="advanceSearchPage__yourSearchCriteriaBox">
          <div className="advanceSearchPage__yourSearchCriteriaText">
            {YOUR_SEARCH_CRITERIA_LABELS.YOUR_SEARCH_CRITERIA_TEXT}
          </div>
          <div className="advanceSearchPage__searchOptionsBox">
            {/* <Box className="AdvancedSearch__searchOptionsBox">
                Ticker:AAPL
              </Box> */}
            <ChipsArray className="advanceSearchPage__chipsArray" />
          </div>
        </Box>
        <Table
          isLoading={isLoading}
          title="Search Results"
          saveSearchButton={<CustomButton
            className="advanceSearchPage__yourSearchCriteriaButton"
            variant="outlined"
            size="medium"
            label="Save Search"
            onClick={handleSaveSearch}
          />}
          columns={columns}
          data={researchReport}
          onSorting={onSorting}
          defaultSortOrder={sortOrder.current}
          defaultOrderField={sortField.current}
          errorMessage={message}
        />
        {totalRecords.current > 0 &&
          <TablePagination dataCount={totalRecords.current} rowsPerPage={recordsPerPage} onChange={onPageChange} currentPage={currentPageNumber} />
        }
      </div>
    </div>
  );
}

export default AdvancedSearch;
