import styled from "@emotion/styled";
import { Box, Link, AppBar, Toolbar } from "@mui/material";
import { Stack } from "@mui/system";
import { ReactComponent as FBlogo } from "icons/fb-logo.svg";
import { ReactComponent as Xlogo } from "icons/x-logo.svg";
import { ReactComponent as Linkedinlogo } from "icons/linkedin-logo.svg";
import { COPYRIGHT_TEXT } from "utils/constants";
import "./Footer.scss";

const StyledToolbar = styled(Toolbar)({
  justifyContent: "space-between",
});

const Footer = () => {
  return (
    <Box sx={{ flexGrow: 1 }} className="footer__container">
      <AppBar position="static" className="footer__app-bar">
        <StyledToolbar className="footer__styled-toolbar">
          <Stack direction="row" spacing={2} className="footer__stack">
            <Link
              href="https://www.facebook.com/pipersandler/"
              target="_blank"
              rel="noopener"
              aria-label="Open Analysts in a new window."
              className="footer__social-logo--fb-logo"
            >
              <FBlogo />
            </Link>
            <Link
              href="https://twitter.com/Piper_Sandler"
              target="_blank"
              rel="noopener"
              aria-label="Open Analysts in a new window."
              className="footer__social-logo--twitter-logo"
            >
              <Xlogo />
            </Link>
            <Link
              href="https://www.linkedin.com/company/pipersandler/"
              target="_blank"
              rel="noopener"
              aria-label="Open Analysts in a new window."
              className="footer__social-logo--linkedin-logo"
            >
              <Linkedinlogo />
            </Link>
          </Stack>
          <div className="footer__copyright-text">{COPYRIGHT_TEXT}</div>
        </StyledToolbar>
      </AppBar>
    </Box>
  );
};

export default Footer;
