import React, { useRef, useState } from "react";
import Link from "@mui/material/Link";
import { DataType } from "components/Common/Table";
import { postRequest, getPlatformXrefRequest } from "api";
import { TABLE_MESSAGES } from "utils/constants";
import { getDocumentPdfURL } from "utils/utilities";

const RECORDS_PER_PAGE = 25;
const columns = [
  {
    title: "Date/Time",
    field: "documentDate",
    sorting: true,
    width: 17,
    type: DataType.STRING,
    customSort: (a, b, order) => {
      return 0;
    },
  },
  {
    title: "Company Name",
    field: "companyName",
    width: 23.5,
    type: DataType.STRING,
  },
  {
    title: "Headline",
    field: "headline",
    sorting: true,
    width: 44.5,
    type: DataType.ANCHOR,
  },
  {
    title: "Rating",
    field: "rating",
    width: 15,
    type: DataType.STRING,
    customSort: (a, b, order) => {
      return 0;
    },
  },
];

export const useResearchReport = (getSearchQueryString, onSymbolClick) => {
  const totalRecords = useRef(0);
  const currentPage = useRef(1);
  const sortField = useRef("documentDate");
  const sortOrder = useRef("desc");
  const lastFilteredStr = useRef("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(TABLE_MESSAGES.START);
  const [researchReport, setResearchReport] = useState([]);

  const getPayload = () => {
    return {
      filterString: lastFilteredStr.current,
      rowCount: RECORDS_PER_PAGE,
      pageNumber: currentPage.current,
      sortField: sortField.current,
      sortOrder: sortOrder.current,
    };
  };

  const fetchNSetReports = async () => {
    setIsLoading(true);
    try {
      const response = await postRequest("researchreport", getPayload());
      const resultData = response.data;
      const researches = resultData.data.map((research) => {
        let d = new Date(research.documentDate);
        let formatedDate = d.toLocaleDateString(
          {},
          { month: "long", day: "numeric", year: "numeric" }
        );
        let formatedTime = d.toLocaleTimeString(
          {},
          { hour: "2-digit", minute: "2-digit", hour12: true }
        );
        return {
          documentDate: (
            <React.Fragment>
              {formatedDate} <br /> {formatedTime}
            </React.Fragment>
          ),
          companyName: research.companyInfo.map((company) => (
            <React.Fragment>
              <Link
                data-symbol={company.symbol}
                onClick={companyClick}
              >{`${company.name} (${company.symbol})`}</Link>
              <br />
            </React.Fragment>
          )),
          headline: research.headline,
          //headlineHref: getDocumentPdfURL(research.documentKey),
          rating: research.rating,
          id: research.documentKey,
        };
      });

      totalRecords.current = resultData.totalRows;
      setResearchReport(researches);
      setIsLoading(false);
      setMessage(TABLE_MESSAGES.NO_RESULT);
    } catch (ex) {
      setResearchReport([]);
      setIsLoading(false);
      let errorToShow = TABLE_MESSAGES.API_ERROR;
      const errorMsg = ex?.response?.data?.error?.message;
      if (errorMsg && errorMsg.includes("Error in Xref")) {
        const msgParts = errorMsg.split("|");
        if (msgParts.length >= 2) {
          msgParts.shift(); // remove first element
          if (msgParts.length > 1) {
            errorToShow = `${msgParts.join(
              ", "
            )} symbols have been delisted. Please modify your search criteria and try again.`;
          } else {
            errorToShow = `${msgParts.join(
              ", "
            )} symbol has been delisted. Please modify your search criteria and try again.`;
          }
        }
      }
      setMessage(errorToShow);
    }
  };

  const getResearchReport = async (
    filterString) => {
    currentPage.current = 1;
    setIsLoading(true);
    filterString = filterString || getSearchQueryString();
    lastFilteredStr.current = filterString;
    //currentPage.current = selectedPage;
    await fetchNSetReports();
  };

  const onPageChange = async (page) => {
    currentPage.current = page;
    await fetchNSetReports();
  };

  const onSorting = async (order, field) => {
    sortField.current = field;
    sortOrder.current = order;
    currentPage.current = 1; //reset page to 1 on sorting
    await fetchNSetReports();
  };

  const companyClick = async (event) => {
    const symbol = event?.target?.dataset?.symbol;
    setIsLoading(true);
    const result = await getPlatformXrefRequest(`symbols/${symbol}`);
    if (result.status === 200 && result?.data?.data) {
      onSymbolClick({ ...result?.data?.data });
    } else {
      setIsLoading(false);
    }
  };

  return {
    columns: columns,
    isLoading,
    researchReport,
    message,
    totalRecords,
    currentPageNumber: currentPage.current,
    sortField,
    sortOrder,
    recordsPerPage: RECORDS_PER_PAGE,
    getResearchReport,
    onPageChange,
    onSorting,
  };
};
