import TagManager from 'react-gtm-module'

export const initiaizeGoogleAnalytics = () => {
  let tagManagerArgs = {
    gtmId: 'GTM-5HC4BGS',
    auth: window.MD.GTM_AUTH_KEY,
    preview: window.MD.GTM_PREVIEW || ''
  }
  // Initialize Google Analytics
  TagManager.initialize(tagManagerArgs)
}