import { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Link from "@mui/material/Link";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import "./Table.scss";
import mockObj from "./mock";
import CustomTableHead from "./components/CustomTableHead";
import { DataType } from "./consts";
import { CustomCheckbox } from "components/Common/Checkbox"
import { CustomButton } from "components/Common/Button";
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as PdfIcon } from "icons/document-file-pdf.svg";
import { getDocumentPdfURL } from "utils/utilities";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy, columns) {
  let columnDef = undefined;
  if (columns && columns.length > 0) {
    columnDef = columns.find(({ field }) => field === orderBy);
  }

  if (columnDef && columnDef.customSort) {
    return (a, b) => columnDef.customSort(a, b, order);
  }
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const TableComponent = (props) => {
  const columns = props.columns || (props.useMock ? mockObj.columns : []);
  const firstSortedCol = columns.find(({ sorting }) => sorting);
  const data = props.data || (props.useMock ? mockObj.data : []);
  const actions = props.actions || [];
  const bulkSelectionAction = props.bulkSelectionAction || [];
  const [order, setOrder] = useState(props.defaultSortOrder || "asc");
  const [orderBy, setOrderBy] = useState(props.defaultOrderField || firstSortedCol?.field);
  const [selectedRowId, setSelectedRowId] = useState([])

  const handleRequestSort = async (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    if (props.onSorting) {
      await props.onSorting(isAsc ? "desc" : "asc", property)
    }
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const fields = columns.map(({ field, type }) => ({ field, type }));

  const handleSelectionClick = (event, id) => {
    const selectedIndex = selectedRowId.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRowId, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRowId.slice(1));
    } else if (selectedIndex === selectedRowId.length - 1) {
      newSelected = newSelected.concat(selectedRowId.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRowId.slice(0, selectedIndex),
        selectedRowId.slice(selectedIndex + 1)
      );
    }

    setSelectedRowId(newSelected);
  };

  return (
    <div>
      {props.isLoading && <div className="table__loaderActive"></div>}
      <Box className="table">
        <div className="table__titleContainer">
          <Typography variant="h4" className="table__title">
            {props.title}
          </Typography>
          <div>{props.saveSearchButton}</div>
        </div>
        {props.saveSearchButton && <div className="table__savedSearchText">To Run Previous Saved Searches, Navigate to Saved Searches at top of the page</div>}
        <TableContainer>
          <Table aria-label="caption table">
            <caption className="table__caption">
              A basic table with a caption
            </caption>
            <CustomTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              columns={columns}
              actions={actions}
            />
            {props.isLoading ? <div className="table__loaderContainer"><CircularProgress className="table__loader" /></div> :
              <TableBody className="table__body">
                {stableSort(data, getComparator(order, orderBy, columns)).map(
                  (row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {fields.map((f, cellIndex) => (
                        <TableCell key={`${rowIndex}_${cellIndex}_${row.id}`}>
                          {
                            {
                              [DataType.STRING]: row[f.field],
                              [DataType.ANCHOR]: <><Link onClick={async () => window.open(await getDocumentPdfURL(row.id), "_blank")}>{row[f.field]}</Link><Link onClick={async () => window.open(await getDocumentPdfURL(row.id), "_blank")} rel="noopener"><PdfIcon className="table__body__pdfIcon" /></Link></>,
                              [DataType.SELECT]: (
                                <div>
                                  <CustomCheckbox
                                    onClick={event => handleSelectionClick(event, row.id)}
                                    className="selectCheckbox"
                                  // checked={isSelected}
                                  />
                                  {row[f.field]}
                                </div>
                              )
                            }[f.type]
                          }
                        </TableCell>
                      ))}
                      {
                        actions && actions.map((action, actionIndex) => (
                          <TableCell key={`${row.id}_${actionIndex}`}>
                            <Link
                              component="button"
                              className="action__link"
                              onClick={(e) => action.onClick(e, row)}
                            >
                              {action.title}
                            </Link>
                          </TableCell>
                        ))
                      }
                    </TableRow>
                  )
                )}
              </TableBody>}
          </Table>
        </TableContainer>
      </Box>
      {
        data.length > 0 &&
        <Box className="table-actions__container">
          {
            bulkSelectionAction && bulkSelectionAction.map((action, index) => (
              <>
                <CustomButton
                  label={action.title}
                  variant="outlined"
                  size="medium"
                  onClick={(e) => {
                    action.onClick(e, selectedRowId).then(() => {
                      setSelectedRowId([]);
                    })
                  }}
                  key={`${action.title}_${index}`}
                  disabled={selectedRowId.length > 0 ? false : true}
                >
                </CustomButton>
              </>
            ))
          }
        </Box>
      }
      {
        (data.length === 0 && props.isLoading === false) ?
          (<Box className="table-actions__noResultContainer">
            <div className="table-actions__noResultBox">
              <ErrorOutlineIcon className="table-actions__errorIcon" />
              <span className="table-actions__noResultText"> {props.errorMessage} </span>
            </div>
          </Box>) : null
      }
    </div>
  );
};

TableComponent.propTypes = {
  title: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      sorting: PropTypes.bool,
      type: PropTypes.string.isRequired,
      customSort: PropTypes.func,
    })
  ).isRequired,
  data: PropTypes.arrayOf(Object),
};

export default TableComponent;
