import axios from 'axios';
import { redirectToLoginPage } from 'auth';

const initialConfig = {
  baseURL: window.MD.API_URL,
  timeout: 15000
};

const axiosClient = axios.create(initialConfig);

axiosClient.defaults.headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
};

axiosClient.interceptors.request.use(config => {
  const accessToken = localStorage.getItem('access_token');
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
}, error => {
  // Do something with request error
  return Promise.reject(error);
});

axiosClient.interceptors.response.use(response => response,  
  async (error) => {
  const isAuthError = error && error.response && error.response.status === 401;
  const isNetworkError = error && error.message && error.message.toLowerCase() === "network error";
  if(isAuthError || isNetworkError){
    redirectToLoginPage()  
  }
});

export function getRequest(URL) {
  return axiosClient.get(`${window.MD.PIPER_BASE_URL}/${URL}`).then(response => response);
}

export function postRequest(URL, payload) {
  return axiosClient.post(`${window.MD.PIPER_BASE_URL}/${URL}`, payload).then(response => response);
}

export function patchRequest(URL, payload) {
  return axiosClient.patch(`${window.MD.PIPER_BASE_URL}/${URL}`, payload).then(response => response);
}

export function deleteRequest(URL) {
  return axiosClient.delete(`${window.MD.PIPER_BASE_URL}/${URL}`).then(response => response);
}

export function getPlatformXrefRequest(URL) {
  return axiosClient.get(`${window.MD.PLATFORM_BASE_URL}/${URL}`).then(response => response);
}

export function postPlatformXrefRequest(URL, payload) {
  return axiosClient.post(`${window.MD.PLATFORM_BASE_URL}/${URL}`, payload).then(response => response);
}

export function concurrentRequests(endpoints) {
  return axios.all(endpoints.map((endpoint) => axiosClient.get(`${window.MD.PIPER_BASE_URL}/${endpoint}`))).then(
    (response) => response,
  );
}

export function bulkDeleteRequest(endpoints) {
  return axios.all(endpoints.map((endpoint) => axiosClient.delete(`${window.MD.PIPER_BASE_URL}/${endpoint}`))).then(
    (response) => response,
  );
}

export function createDocLink (documentKey){
 const url =`${window.MD.PIPER_BASE_URL}/researchDocument?docKey=${documentKey}`; 
  return axiosClient
    .get(url, {
      responseType: 'blob'
    })
    .then((response)=> URL.createObjectURL(response.data))
    .catch((err) => {
      console.log(err)
    })
}
