import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { Layout } from 'components/Layout';
import theme from "./theme";
import { setAccessToken, fetchQueryString, redirectToLoginPage } from "auth";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    if (fetchQueryString().includes('refid')) {
      setAccessToken();
      window.location.replace('/');
    }
    else if (!localStorage.getItem('access_token')) {
      redirectToLoginPage();
    }
  }, []);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Layout />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
