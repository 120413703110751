import styled from "@emotion/styled";
import { Box, Link, Divider, AppBar, Toolbar } from "@mui/material";
import { Stack } from "@mui/system";
import { ReactComponent as Logo } from "icons/logo.svg";
import "./Header.scss";

const StyledToolbar = styled(Toolbar)({
  justifyContent: "space-between",
});

const Header = () => {
  return (
    <Box sx={{ flexGrow: 1 }} className="header__container">
      <AppBar position="static" className="header__app-bar">
        <StyledToolbar className="header__styled-tool-bar">
          <Logo />
          <Stack
            direction="row"
            spacing={2}
            divider={
              <Divider
                className="header__divider"
                variant="fullWidth"
                orientation="vertical"
              />
            }
            className="header__toolbar"
          >
            <Link
              href="https://www.pipersandler.com/people?expertise=427"
              target="_blank"
              rel="noopener"
              aria-label="Open Analysts in a new window."
            >
              Analysts
            </Link>
            <Link
              href="https://www.pipersandler.com/research"
              target="_blank"
              rel="noopener"
              aria-label="Open Coverage in a new window."
            >
              Coverage
            </Link>
            <Link
              href="https://www.pipersandler.com/researchdisclosures"
              target="_blank"
              rel="noopener"
              aria-label="Open Disclosures in a new window."
            >
              Disclosures
            </Link>
            <Link
              href="https://www.pipersandler.com/conferences-and-events"
              target="_blank"
              rel="noopener"
              aria-label="Open Conferences in a new window."
            >
              Conferences
            </Link>
            </Stack>            
        </StyledToolbar>
        <Stack
            direction="row"
            spacing={2}
            divider={
              <Divider
                className="header__divider"
                variant="fullWidth"
                orientation="vertical"
              />
            }
            className="header__toolbar-guide"
          >
            <Link 
            href="/Research Portal How-to-Guide_October 2023.pdf"
            target="_blank"
            download="How-To Guide"
            rel="noopener"
            aria-label="Open Conferences in a new window."
            > 
            How-To Guide            
                </Link> 
          </Stack>
      </AppBar>
    </Box>
  );
};

export default Header;
