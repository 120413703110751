import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Slide from '@mui/material/Slide';
import './CustomDialog.scss'
import { CustomButton } from '../Button';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const CustomDialog = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open responsive dialog
            </Button> */}
            <Dialog
                className='CustomDialog__MuiBackdrop'
                fullScreen={fullScreen}
                open={props.dialogState}
                // open={true}
                onClose={props.onClose}
                aria-labelledby="responsive-dialog-title"
                TransitionComponent={Transition}
            >
                <div className="CustomDialog__titleContainer"><DialogTitle className="CustomDialog__title" id="responsive-dialog-title">
                    {props.title}
                </DialogTitle></div>
                <DialogContent>
                    <DialogContentText className="CustomDialog__content">
                        {props.dialogContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='CustomDialog__buttonContainer'>
                    <CustomButton label={props.cancelButtonTxt} variant="text" className="CustomDialog__cancelButton" autoFocus onClick={props.onClose} />
                    <CustomButton label={props.deleteButtonTxt} variant="outlined" className='CustomDialog__deleteButton' onClick={props.onConfirm} />
                </DialogActions>
            </Dialog>
        </div>
    );
}

CustomDialog.propTypes = {
    dialogContent: PropTypes.string,
    cancelButtonTxt: PropTypes.string,
    deleteButtonTxt: PropTypes.string
};

export default CustomDialog;