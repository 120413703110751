import { React, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./SuccessModal.scss";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#f4f4f4",
  boxShadow: 24,
  p: 4,
};

export default function SuccessModal(props) {
  const handleClose = () => {
    props.setSuccessModalState(false);
  };

  return (
    <div>
      <Modal
        open={props.state}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="successModal__container">
            {
              props.isError ?
                <>
                  <ErrorOutlineIcon className="successModal__checkCircleIcon" />
                  {props.errorMsg}
                </>
                :
                <>
                  <CheckCircleIcon className="successModal__checkCircleIcon" />
                  Search saved successfully in the saved searches page!
                </>
            }
            <CloseIcon className="successModal__closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
