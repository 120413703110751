import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./Modal.scss";
import { CustomButton } from "components/Common/Button";
import { SuccessModal } from "../SuccessModal";
import { postRequest } from "api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: 588,
  minHeight: 504,
  transform: "translate(-50%, -50%)",
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  p: 4,
};

const CustomModal = (props) => {
  const [searchTextBox, setSearchTextBox] = useState(props.searchName || "");
  const [emailTextBox, setEmailTextBox] = useState(props.emailID || "");
  const [radioNoButtonChecked, setRadioNoButtonChecked] = useState("checked");
  const [radioYesButtonChecked, setRadioYesButtonChecked] = useState("");
  const [disbaleBtn, setDisbaleBtn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [enableNameErrorMsg, setEnableNameErrorMsg] = useState(false);

  useEffect(() => {
    setEmailTextBox(props.emailID);
  }, [props.emailID])

  useEffect(() => {
    setSearchTextBox(props.searchName);
  }, [props.searchName])

  useEffect(() => {
    if (typeof props.isEmailAlert !== 'undefined') {
      if (props.isEmailAlert) {
        handleYesRadio();
      } else {
        handleNoRadio();
      }
    }
  }, [props.isEmailAlert])

  const handleYesRadio = () => {
    setRadioYesButtonChecked("checked");
    setRadioNoButtonChecked("");
    //
  };
  const handleNoRadio = () => {
    setRadioNoButtonChecked("checked");
    setRadioYesButtonChecked("");

    //
  };
  const handleClose = () => {
    props.callBackFn(false);
  };
  const handleSaveSearch = async () => {
    const isNameValid = ValidateNameSearchBox();
    const isEmailValid = validateEmail();
    if (!isNameValid) {
      return;
    }
    else if (!isEmailValid) {
      return;
    }
    setDisbaleBtn(true);
    let savedSuccessfully = true;
    try {
      if (radioYesButtonChecked === "checked") {
        const response = await postRequest(`Alerts?emailID=${emailTextBox}`);
        savedSuccessfully = response.status === 200;
      }

      if (savedSuccessfully) {
        const savedSearchCriteria = {
          "isAlertable": radioYesButtonChecked === "checked" ? "Yes" : "No",
          "email": emailTextBox,
          "searchString": `"${props.getSearchCriteria()}"`
        }
        const res = await postRequest(`SavedSearch?savedSearchName=${searchTextBox}`, savedSearchCriteria);
        savedSuccessfully = res.status === 200;
        props.setSuccessModalState(true);
      }
    }
    catch (ex) {
      savedSuccessfully = false;
      console.log(ex)
      props.setSuccessModalState(false);
      alert("error in saving details")
    }
    props.callBackFn(false);
    //props.setSuccessModalState(true);
    setDisbaleBtn(false);
  };

  const validateEmail = () => {
    if (radioNoButtonChecked === "checked") {
      setErrorMessage("");
      return true;
    }
    if (radioYesButtonChecked !== "" && emailTextBox === "") {
      setErrorMessage("Please enter email address.");
      return false;
    }
    const isEmail = (email) =>
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i.test(email);

    if (emailTextBox !== "") {
      if (!isEmail(emailTextBox)) {
        setErrorMessage("Please enter a valid email address.");
        return false;
      }
      else {
        setErrorMessage("")
        return true;
      }
    }
    else setErrorMessage("");
    return true;
  }

  const ValidateNameSearchBox = () => {
    if (searchTextBox === "" || searchTextBox === undefined) {
      setEnableNameErrorMsg(true);
      return false;
    }
    else {
      setEnableNameErrorMsg(false);
      return true;
    }
  }

  return (
    <div>
      <Modal
        open={props.state}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="">
          <Box sx={style}>
            <div className="modal__container">
              <div className="modal__modalheadingContainer">
                <div className="modal__heading">Save Search</div>
              </div>
              <div className="modal__body">
                <label className="modal__searchlabel">Search</label>
                <div className="modal__inputBox">
                  <input
                    placeholder="Enter search name"
                    className="modal__searchInput"
                    type="text"
                    value={searchTextBox}
                    onChange={(e) => {
                      setSearchTextBox(e.target.value);
                    }}
                  />
                  {enableNameErrorMsg && <div className="modal__emailErrorMessage">Please name your search.</div>}
                </div>
                <div className="">
                  <label className="modal__emailLabel">Email Alerts</label>
                  <div className="modal__radio">
                    <input
                      id="yesRadioBtn"
                      className="modal__radioInput"
                      type="radio"
                      onChange={handleYesRadio}
                      checked={radioYesButtonChecked}
                    />
                    <label for="yesRadioBtn" className="modal__radioInputLabel">Yes</label>
                    <input
                      id="noRadioBtn"
                      className="modal__radioInput"
                      type="radio"
                      onChange={handleNoRadio}
                      checked={radioNoButtonChecked}
                    />
                    <label for="noRadioBtn" className="modal__radioInputLabel">No</label>
                  </div>
                </div>
                <label className="modal__searchlabel">Email Address</label>
                <div className="modal__inputBox">
                  <input
                    placeholder="Enter Email Address"
                    className="modal__searchInput"
                    type="text"
                    value={emailTextBox}
                    onChange={(e) => {
                      setEmailTextBox(e.target.value);
                      //validateEmail();
                    }}
                    onBlur={() => validateEmail()}
                  />
                  <div className="modal__emailErrorMessage">{errorMessage}</div>
                </div>
              </div>
              <div className="modal__buttonContainer">
                <CustomButton
                  variant="text"
                  className="modal__cancelButton"
                  label="CANCEL"
                  disableRipple="true"
                  onClick={handleClose}
                  disabled={disbaleBtn}
                />
                <CustomButton
                  variant="outlined"
                  className="modal__saveSearchButton"
                  label="SAVE SEARCH"
                  onClick={handleSaveSearch}
                  disabled={disbaleBtn}
                />
              </div>
            </div>
          </Box>
        </div>
      </Modal >
    </div >
  );
};

export default CustomModal;
