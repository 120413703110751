import React, { useState, useCallback, useEffect, useRef } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as Glass } from "icons/magnifying-glass.svg";
import "./Autocomplete.scss";

const debounce = (fn, delay = 250) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

const AutocompleteComponent = ({ label, onSelect, onInputChange, showValueOnSelect, placeholder, selectedValues, defaultValue }) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(defaultValue);
  const [inputValue, setInputValue] = useState('');
  const loading = useRef(false);

  const loadOptionsDebounced = useCallback(debounce((inputValue, callback) => {
    onInputChange(inputValue).then(resp => callback(resp))
  }, 500), []);

  const handleChange = (value) => {
    if (onSelect) {
      onSelect(value);
    }

    if (showValueOnSelect) {
      setValue(value);
    }
    loading.current = false;
    setOptions([]);
  };

  useEffect(() => {
    if (typeof selectedValues !== "undefined" && showValueOnSelect && Object.keys(selectedValues).length === 0) {
      setValue(null);
    }
  }, [selectedValues, showValueOnSelect]);

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue]);

  useEffect(() => {
    let active = true;
    if (inputValue === '') {
      setOptions([]);
      loading.current = false;
      return undefined;
    }
    loading.current = true;
    loadOptionsDebounced(inputValue, (resp) => {
      if (active) {
        let newOptions = [];
        if (resp) {
          newOptions = [...newOptions, ...resp];
        }
        setOptions(newOptions);
        loading.current = false;
      }
    })
    return () => {
      active = false;
    };
  }, [inputValue, loadOptionsDebounced]);

  return (
    <div className="autocomplete">
      <label className="autocomplete__label">{label}</label>
      <Autocomplete
        className="autocomplete__wrapper autocomplete__loader"
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        sx={{
          [`& .${autocompleteClasses.popupIndicator}`]: {
            transform: "none"
          },
          "& fieldset": { border: 'none' },
          "& .MuiAutocomplete-clearIndicator": {
            visibility: "hidden !important"
          }
        }}
        isOptionEqualToValue={(option, value) => option.value.symbol === value.value.symbol}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.value.symbol
        }
        blurOnSelect
        options={options}
        loading={loading.current}
        value={value}
        onChange={(event, newValue) => {
          handleChange(newValue);
        }}
        selectOnFocus={false}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        getOptionDisabled={(option) => {
          let isDisabled = false;
          if (selectedValues && selectedValues.length) {
            isDisabled = selectedValues.some(({ symbol }) => symbol === option.value.symbol);
          } else {
            isDisabled = value ? option.value.symbol === value.value.symbol : false;
          }
          return isDisabled
        }}
        forcePopupIcon
        popupIcon={<Glass />}
        filterOptions={(x) => x}
        renderOption={(props, option) => (
          <li {...props}>{option.label}</li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {open && loading.current ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
}

export default AutocompleteComponent;