import { createDocLink } from 'api';
import { TYPEOFRESEARCH, ONE_CLICK_SEARCH } from './constants';

function isInternal() {
  return false;
	//return gIsInternal; // set in Page.custom.asp
}// -- Loaded:util.js


function getFieldDesc(field) {
  switch (field) {
    case "IRPID":
      return "Provider";
    case "AUTHORS":
      return "Analysts";
    case "INDUSTRYCODES":
      return "Industry (Piper Jaffray)";
    case "INDUSTRYCODES_CSFB":
      return "Industry (Credit Suisse)";
    case "SUBJECT":
      return "Subject (Piper Jaffray)";
    case "SUBJECT_CSFB":
      return "Subject (Credit Suisse)";
    case "TYPEOFRESEARCH":
      return "Research Type";
    case "DocumentDate":
      return "Document Date";
    default :
      return field;
  }
}

function getComparatorDesc(comparator) {
	switch(comparator.toLowerCase())
	{
		case "equ":
			return "is";
		case "like":
			return "contains";
		case "geq":
			return "greater than";
		case "leq":
			return "less than";
		case "neq":
			return "is not";
    default :
      return comparator.toLowerCase();
	}
}

function getValuesArray(field, rVals, strJoin, isMarketUpdate, industriesObj) {

	let rFormattedValues = [];
	switch(field)
	{
		case "IRPID": // 506 (provider)
		case "AUTHORS":
      rFormattedValues = [...rVals]
      break;
		case "INDUSTRYCODES":
      rFormattedValues = formatValues(industriesObj, rVals, isMarketUpdate);
      break;
		case "SUBJECT":
		case "SUBJECT_CSFB":
      rFormattedValues = [...rVals]
      break;
		case "TYPEOFRESEARCH":
      rFormattedValues = formatValues(TYPEOFRESEARCH, rVals, isMarketUpdate);
      break;
		default:
			rFormattedValues = rVals;
			break;
	}
	return rFormattedValues.join(strJoin);
}

function formatValues(object, rVals , isMarketUpdate) {
  const rFormattedValues = [];
  for(var i=0; i < rVals.length; i++) {
    var label = getLabel(object, rVals[i]);
    if (isMarketUpdate && label.match(/morningstar/gi)) {
      label = "Morningstar - Significant Updates";	
    }
    rFormattedValues.push( label );
  }
  return rFormattedValues;
}

function getLabel(object, key) {
  if(object[key]) {
    return object[key].replace("&amp;", "&");
  }
  return "notFound";
}

export const currentYear = () => {
  let dateobj = new Date();
  let copyrightYear = dateobj.getFullYear();
  return copyrightYear;
};

export const generateSearchDescription = (criteriaString, industries) => {
  //FIELD::EQU::C&&FIELD2::NEQ::A||B
	let rFormattedCriteria = [];
	let rc = criteriaString.length ? criteriaString.split("&&") : [];
	
	let isMarketUpdate = false;

  const industriesObj = industries.reduce((prev, cur) => {
    prev[cur.IndustryCode] = cur.Industry;
    return prev;
  }, {});

  const obj = rc.map(val => {
    const r = val.split("::");
    const rVals = r[2].split("||");
    const desc = getFieldDesc(r[0]);
    if(desc.match(/ismarketupdate/gi))
    {
      isMarketUpdate = true;
    }
    return {
      field: r[0],
      fieldDesc : desc,
      comparatorDesc: getComparatorDesc(r[1]),
      values : rVals
    }
  })

  obj.forEach(o => {
    if (!o.fieldDesc.match(/ismarketupdate/gi) && (!isInternal() && !o.fieldDesc.match(/Provider/))) {
      rFormattedCriteria.push( o.fieldDesc + " " + o.comparatorDesc + " " + getValuesArray(o.field, o.values, " or ", isMarketUpdate, industriesObj) );
    }
  })
	return rFormattedCriteria.join("<br />and ");
}

export const getQueryStringParameterValue = key => {
  if (!window.location.search) return undefined

  const params =
    (
      /^[?#]/.test(window.location.search)
        ? window.location.search.slice(1)
        : window.location.search
    )
      .split('&')
      .reduce((
        parameters,
        param
      ) => {
        const [keyIndex, value] = param.split('=')
        parameters[keyIndex] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
        return parameters
      }, {})
  
    if (!params || Object.keys(params).length < 1) return undefined
  
    return params[key]
  }

export const getAccessToken = () => {
  return localStorage.getItem('access_token');
}

export const lastWeekDate = () =>{
  const currentDate = new Date();
  const last7daysdate=new Date(currentDate.setDate(currentDate.getDate() - 7));
  return last7daysdate.toUTCString();
}

export const getDocumentPdfURL = async (documentKey) => {
  const url = await createDocLink(documentKey);
  return url;
}

export const selectFixedDuration = (value) => {
 let date = new Date();
 let strValue = value.toString();
 switch (strValue) {
   case "In the last hour" :
   date.setHours(date.getHours()-1)
   break;
   case "In the last 2 hours" :
   date.setHours(date.getHours()-2)
   break;
   case "In the last 6 hours" :
   date.setHours(date.getHours()-6)
   break;
   case "In the last 12 hours" :
   date.setHours(date.getHours()-12)
   break;
   case "In the last 18 hours" :
   date.setHours(date.getHours()-18)
   break;
   case "In the last day" :
   date.setHours(date.getDate() - 1)
   break;
   case "In the last week" :
   date.setDate(date.getDate() - 7)
   break;
   case "In the last 2 weeks" :
   date.setDate(date.getDate() - 14)
   break;
   case "In the last month" :
    date.setMonth(date.getMonth() - 1)
   break;
   case "In the last 3 months" :
    date.setMonth(date.getMonth() - 3)
   break;
   case "In the last 6 months" :
    date.setMonth(date.getMonth() - 6)
   break;
   case "In the last year" :
    date.setFullYear(date.getFullYear() - 1)
   break;    
 }
 return date.toGMTString();
}

export const last7DaysSearchString =()=>{
  const last7daysDate= lastWeekDate();
  let last7DaysSearch =  ONE_CLICK_SEARCH.MOST_RECENT_RESEARCH;
  let result = last7DaysSearch.replace("{currentDate}", last7daysDate) ;
  last7DaysSearch = result;
  const resultString = last7DaysSearch.replace("{currentDate}", lastWeekDate())
  return resultString;
}
