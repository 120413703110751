import * as CryptoJS from 'crypto-js';

const hostname = window.location.hostname;

function decryptString(encryptText, key, iv){
    let encryptTextWA = CryptoJS.enc.Base64.parse(encryptText);
    let keyWA = CryptoJS.enc.Utf8.parse(key);
    let ivWA = CryptoJS.enc.Utf8.parse(iv);
    let encryptTextParams = { ciphertext: encryptTextWA };

    let decrypted = CryptoJS.AES.decrypt(
        encryptTextParams,
        keyWA, 
        { iv: ivWA }
    );
    return decrypted.toString(CryptoJS.enc.Utf8); 
}

function fetchQueryString() {
    const queryString = window.location.search;
    return queryString;
}

function redirectToLoginPage(){
    let redirectLocation;
    hostname === 'pipersandler.markitdigital.com' ? redirectLocation = 'https://secure.psc.com/access' : redirectLocation = 'https://betaprodpreview1.pjc.com/access';
    window.location.href = redirectLocation;
}

function fetchAccessToken() {
let accessToken;    
const queryParams = fetchQueryString();
const splitUrl = queryParams?.split('?');
const queryString = splitUrl[1];
const splitQueryString = queryString?.split('&');
const refId = splitQueryString[0].slice(6);
const IV = splitQueryString[1].slice(3);

let decryptKey;
if(hostname === 'pipersandler.markitqa.com') {
    decryptKey = 'izjhucdVY/LqMybZ6yq3jw==';
}
else if(hostname === 'pipersandler.markitdigital.com'){
    decryptKey = 'Xc22Mj4OIMAMq1eMp9c/aw==';
}
else decryptKey = 'izjhucdVY/LqMybZ6yq3jw==';
const decryptedString = decryptString(refId, decryptKey, IV);
const splitDecryptedString = decryptedString.split('&');
accessToken = splitDecryptedString[0].split('access_token=');
accessToken = accessToken[1];
let userId = splitDecryptedString[1].split('User_ID=');
userId = userId[1];
return accessToken;
}

const setAccessToken = () => {
const accessToken = fetchAccessToken() || null;
window.localStorage.setItem("access_token", accessToken);
}

export {setAccessToken, fetchQueryString, redirectToLoginPage};